<template>
  <div
    class="registration-wrapper"
    :class="{ mobile: isMobile, landing: props.isLanding }"
    data-t="registration"
  >
    <WelcomeBanner v-if="!isMobile && !props.isLanding" key="welcome-banner" />
    <div class="registration-form-wrapper">
      <StDrawerHeader
        v-if="isMobile"
        :with-back-button="isLast"
        :with-close-button="!isLanding"
        :data-t="{ root: 'form-header' }"
        :title="t('authorization.registration')"
        @back="handleSecondStepGoBack"
      />
      <div class="registration-form" data-t="registration-form-cewe">
        <KeepAlive :max="5">
          <FirstStep
            v-if="isCurrent(0)"
            :is-mobile="isMobile"
            :is-google-enabled="isGoogleEnabled"
            :is-email-enabled="isEmailEnabled"
            :is-landing="isLanding"
            @success="handleFirstStepSuccess"
            @finish-tg="handleRegistrationFinish"
          />
          <SecondStep
            v-else-if="isCurrent(1) && firstStepData"
            :first-step-data="firstStepData"
            :is-mobile="isMobile"
            :is-landing="isLanding"
            @back="handleSecondStepGoBack"
            @success="handleRegistrationFinish"
          />
        </KeepAlive>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useSettingsStore } from '@st/core/stores/useSettingsStore'
import { useUserStore } from '@st/user/stores/useUserStore'
import { useAnalytics } from '@st/analytics/composables/useAnalytics'
import FirstStep from './parts/FirstStep.vue'
import SecondStep from './parts/SecondStep.vue'
import WelcomeBanner from './parts/WelcomeBanner.vue'

interface FirstStepData {
  email: string
  password: string
  isCodeSent: boolean
}

const { t } = useI18n()
const { user, isJustRegisteredUser } = storeToRefs(useUserStore())
const { sendEvent } = useAnalytics()
const { isMobile } = usePlatform()

const props = withDefaults(
  defineProps<{
    isLanding?: boolean
  }>(),
  {
    isLanding: false,
  },
)
const { isCurrent, goToNext, goToPrevious, isLast } = useStepper([0, 1])

const firstStepData = ref<FirstStepData>()

function handleFirstStepSuccess(data: FirstStepData) {
  goToNext()
  firstStepData.value = data
}

function handleSecondStepGoBack() {
  goToPrevious()
}

const { settings } = storeToRefs(useSettingsStore())
const isGoogleEnabled = computed(
  () => !!settings.value?.oidcGoogleRegistrationEnabled,
)

const isEmailEnabled = computed(
  () => !!settings.value?.emailRegistrationEnabled,
)

const router = useRouter()
const route = useRoute()
function handleRegistrationFinish() {
  if (user.value) {
    isJustRegisteredUser.value = true
    sendEvent('registration')
  }
  if (route.path.includes('promo')) {
    globalThis.location.href = '/bonuses'
  } else {
    router.push('/bonuses')
  }
}
</script>

<style scoped>
.registration-form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  width: 480px;
  min-height: 698px;
  margin-left: auto;
}

.registration-form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  height: 100%;
  padding: var(--spacing-450) var(--spacing-600) var(--spacing-400);
}

.registration-wrapper {
  display: flex;
  gap: var(--spacing-0);
  align-items: center;

  width: 856px;
  height: 698px;
  padding: var(--spacing-100);

  background-color: var(--background-base);
  border-radius: var(--border-radius-200);

  &.landing {
    width: auto;
    height: auto;
    padding: 0;
    border-radius: var(--border-radius-300);
  }

  &.mobile {
    width: 100%;
    height: 100%;
    padding: 0;

    background: var(--background-base);
    border-radius: 0;

    .registration-form {
      padding: 0 var(--spacing-200);
      padding-bottom: var(--spacing-200);
    }

    .registration-form-wrapper {
      gap: var(--spacing-050);
      width: 100%;
      height: 100%;
      min-height: 0;
    }
  }

  &.mobile.landing {
    border-radius: var(--border-radius-150);

    .registration-form {
      padding-bottom: var(--spacing-050);
    }
  }
}

.header-text {
  margin: 0;
  padding: var(--spacing-100) 0;
  font: var(--mobile-title-2-semibold);
}
</style>
